import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'
import amplifyconfig from 'amplifyconfiguration.json'
import { Smallest as Logo } from 'components/logo'
import 'index.css'
import 'typeface-roboto'

const App = React.lazy(() => import('app'))
const AuthenticationProvider = React.lazy(() => import('hooks/use-authentication'))
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <React.Suspense
      fallback={<Logo className="w-14 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />}
    >
      <AuthenticationProvider config={amplifyconfig}>
        <App />
      </AuthenticationProvider>
    </React.Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
